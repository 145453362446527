
import {defineComponent} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {mapActions, mapGetters} from "vuex";
import DashboardMetrics from './dashboard/Metrics.vue';
// import DashboardActionLog from './dashboard/ActionLog.vue';
import InvalidShop from '@/components/shopify/InvalidShop.vue';
// import AdStatusesMetric from "@/views/dashboard/Metrics/AdStatusesMetric.vue";
import CPA from "@/views/dashboard/Metrics/CPA.vue";
import SalesByHour from "@/views/dashboard/Metrics/SalesByHour.vue";
import ProductsTable from "@/views/dashboard/Metrics/ProductsTable.vue";
import CampaignTable from "@/views/dashboard/Metrics/CampaignTable.vue";
import Sales from "@/views/dashboard/Metrics/Sales.vue";
import Roas from "@/views/dashboard/Metrics/Roas.vue";
import TopSources from "@/views/dashboard/Metrics/TopSources.vue";
import UTMTable from "@/views/dashboard/Metrics/UtmTable.vue";

export default defineComponent({
  name: "dashboard",
  components: {
    DashboardMetrics,
    InvalidShop,
    // AdStatusesMetric,
    CPA,
    SalesByHour,
    ProductsTable,
    CampaignTable,
    Sales,
    Roas,
    TopSources,
    UTMTable
  },

  computed: {
    ...mapGetters({
      selectedShopifyShop: 'shopify/shops/selected',
    }),
  },

  methods: {
    ...mapActions({
      fetchStats: 'shopify/shops/dashboard/fetchStats',
      fetchKeyMetrics: 'shopify/shops/dashboard/fetchKeyMetrics'
    }),
  },

  watch: {
    'selectedShopifyShop.id'() {
      this.fetchStats({force: true});
      this.fetchKeyMetrics();
    }
  },

  mounted() {
    setCurrentPageTitle("Dashboard");
    this.fetchStats({force: true});
    this.fetchKeyMetrics();
  },
});
