<template>


  <template v-if="selectedShopifyShop && selectedShopifyShop.facebookAdAccounts && selectedShopifyShop.facebookAdAccounts.length">

    <div class="row g-5 g-xl-8 mt-3">
      <dashboard-metrics />
    </div>

    <div class="row g-5 g-xl-8 mt-3">
      <div class="col-xl-4">
        <Sales />
      </div>
      <div class="col-xl-4">
        <Roas />
      </div>
      <div class="col-xl-4">
        <CPA />
      </div>
    </div>

    <div class="row g-5 g-xl-8 mt-3">
      <div class="col-xl-12">
        <SalesByHour />
      </div>

      <!--        <div class="col-xl-4">-->
      <!--          <AdStatusesMetric />-->
      <!--        </div>-->
    </div>

    <div class="row g-5 g-xl-8 mt-3">
      <div class="col-xl-12">
        <CampaignTable />
      </div>
    </div>

    <div class="row g-5 g-xl-8 mt-3">
      <div class="col-xl-12">
        <ProductsTable />
      </div>
    </div>

    <div class="row g-5 g-xl-8 mt-3">
      <div class="col-xl-8">
        <TopSources />
      </div>
      <div class="col-xl-4">
        <UTMTable />
      </div>
    </div>

  </template>
  <template v-else>

    <invalid-shop />

  </template>

</template>

<script lang="ts">
import {defineComponent} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {mapActions, mapGetters} from "vuex";
import DashboardMetrics from './dashboard/Metrics.vue';
// import DashboardActionLog from './dashboard/ActionLog.vue';
import InvalidShop from '@/components/shopify/InvalidShop.vue';
// import AdStatusesMetric from "@/views/dashboard/Metrics/AdStatusesMetric.vue";
import CPA from "@/views/dashboard/Metrics/CPA.vue";
import SalesByHour from "@/views/dashboard/Metrics/SalesByHour.vue";
import ProductsTable from "@/views/dashboard/Metrics/ProductsTable.vue";
import CampaignTable from "@/views/dashboard/Metrics/CampaignTable.vue";
import Sales from "@/views/dashboard/Metrics/Sales.vue";
import Roas from "@/views/dashboard/Metrics/Roas.vue";
import TopSources from "@/views/dashboard/Metrics/TopSources.vue";
import UTMTable from "@/views/dashboard/Metrics/UtmTable.vue";

export default defineComponent({
  name: "dashboard",
  components: {
    DashboardMetrics,
    InvalidShop,
    // AdStatusesMetric,
    CPA,
    SalesByHour,
    ProductsTable,
    CampaignTable,
    Sales,
    Roas,
    TopSources,
    UTMTable
  },

  computed: {
    ...mapGetters({
      selectedShopifyShop: 'shopify/shops/selected',
    }),
  },

  methods: {
    ...mapActions({
      fetchStats: 'shopify/shops/dashboard/fetchStats',
      fetchKeyMetrics: 'shopify/shops/dashboard/fetchKeyMetrics'
    }),
  },

  watch: {
    'selectedShopifyShop.id'() {
      this.fetchStats({force: true});
      this.fetchKeyMetrics();
    }
  },

  mounted() {
    setCurrentPageTitle("Dashboard");
    this.fetchStats({force: true});
    this.fetchKeyMetrics();
  },
});
</script>
